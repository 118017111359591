import { CodeSurfer } from "code-surfer";
import khoa_fb from "../../../../images/ftw/khoa_fb.png";
import handlebars from "../../../../images/ftw/handlebars_logo.png";
import mvc from "../../../../images/ftw/mvc.png";
import * as React from 'react';
export default {
  CodeSurfer,
  khoa_fb,
  handlebars,
  mvc,
  React
};