export default {
    container: {
        width: "50%"
    },
    red: {
        color:"red"
    },
    colors: {
        text: 'black',
        background: 'white',
        primary: '#b01010'
    },
    styles: {

        h5: {
            fontStyle: "italic",
            fontWeight: "normal",
            fontSize: "1.5rem",
            color: "grey",
            margin: "0px 0px 0px 0px",
        },
        div: {
            marginLeft: '10%',
            marginRight: "10%",
        },
        h3: {
            textAlign: 'center'
        },
        strong: {
            backgroundImage: 'linear-gradient(transparent 55%,#ff6961 55%,#ff6961 95%,transparent 95%)',
            backgroundSize: '100% 1.2em',
            backgroundPosition: '0.5em 0px',
            backgroundRepeat: 'no-repeat space'
        },
        b: {
            color: 'red',
            textAlign: "left"
        },
        img: {
            width: null,
            height: null
        },
        li: {
            textAlign: 'left',
            marginTop: "5%",
        },
        td: {
            padding: '10px;'
        }
    }
}
