import { CodeSurfer } from 'code-surfer';
import CSTheme, { CSSlide, CSSplit } from "../../../../decks/theme";
import InvertCS from "../../../../decks/invertCS";
import { useState } from 'react';
import * as React from 'react';
export default {
  CodeSurfer,
  CSTheme,
  CSSlide,
  CSSplit,
  InvertCS,
  useState,
  React
};