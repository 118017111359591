import { CodeSurfer } from 'code-surfer';
import CSTheme, { CSSplit, CenterFull } from "../../../../decks/theme";
import InvertCS from "../../../../decks/invertCS";
import * as React from 'react';
export default {
  CodeSurfer,
  CSTheme,
  CSSplit,
  CenterFull,
  InvertCS,
  React
};