import { CodeSurfer } from 'code-surfer';
import CSTheme from "../../../../decks/theme";
import mongo from "../../../../images/ftw/wk8/mongo.png";
import models from "../../../../images/ftw/wk8/models.jpg";
import data_growth from "../../../../images/ftw/wk8/data_growth.jpg";
import * as React from 'react';
export default {
  CodeSurfer,
  CSTheme,
  mongo,
  models,
  data_growth,
  React
};