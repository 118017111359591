import { CodeSurfer } from 'code-surfer';
import CSTheme from "../../../../decks/theme";
import InvertCS from "../../../../decks/invertCS";
import { useState } from 'react';
import interior_designer from "../../../../images/ftw/precourse/interior_designer.jpg";
import construction from "../../../../images/ftw/precourse/construction.jpg";
import empty_room from "../../../../images/ftw/precourse/empty_room.jpg";
import bedroom from "../../../../images/ftw/precourse/bedroom.jpg";
import office from "../../../../images/ftw/precourse/office.jpg";
import * as React from 'react';
export default {
  CodeSurfer,
  CSTheme,
  InvertCS,
  useState,
  interior_designer,
  construction,
  empty_room,
  bedroom,
  office,
  React
};