import { CodeSurfer } from 'code-surfer';
import CSTheme, { CSSlide, CSSplit } from "../../../../decks/theme";
import InvertCS from "../../../../decks/invertCS";
import { useState } from 'react';
import charles from "../../../../images/ftw/precourse/chuon_charles.png";
import minh from "../../../../images/ftw/precourse/minh_do.jpg";
import badtime from "../../../../images/ftw/precourse/badtime.png";
import fellini from "../../../../images/ftw/precourse/fellini.png";
import goethe from "../../../../images/ftw/precourse/goethe.png";
import alphabetical from "../../../../images/ftw/precourse/alphabetical.png";
import zoolander from "../../../../images/ftw/precourse/zoolander.jpg";
import yoda from "../../../../images/ftw/precourse/yoda.gif";
import jake from "../../../../images/ftw/precourse/sucking_jake.gif";
import together from "../../../../images/ftw/precourse/together.gif";
import fridge from "../../../../images/ftw/precourse/fridge.jpg";
import * as React from 'react';
export default {
  CodeSurfer,
  CSTheme,
  CSSlide,
  CSSplit,
  InvertCS,
  useState,
  charles,
  minh,
  badtime,
  fellini,
  goethe,
  alphabetical,
  zoolander,
  yoda,
  jake,
  together,
  fridge,
  React
};