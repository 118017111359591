import { CodeSurfer } from "code-surfer";
import yay from "../../../../images/ftw/yay.png";
import slide2 from "../../../../images/ftw/slide_2.png";
import howel from "../../../../images/ftw/howel.png";
import bogo from "../../../../images/ftw/bogo.png";
import bigo from "../../../../images/ftw/bigo.png";
import arraybigo from "../../../../images/ftw/arraybigo.png";
import * as React from 'react';
export default {
  CodeSurfer,
  yay,
  slide2,
  howel,
  bogo,
  bigo,
  arraybigo,
  React
};