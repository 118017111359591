import { CodeSurfer } from 'code-surfer';
import CSTheme from "../../../../decks/theme";
import InvertCS from "../../../../decks/invertCS";
import iversonPractice from "../../../../images/iverson_practice.jpg";
import codingWhiteboard from "../../../../images/coding_whiteboard.jpg";
import * as React from 'react';
export default {
  CodeSurfer,
  CSTheme,
  InvertCS,
  iversonPractice,
  codingWhiteboard,
  React
};