/** @jsx jsx */
import { jsx } from "theme-ui"

export const InvertCS = ({ ...props }) => (
  <div
    {...props}
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "100%",
      height: "100%",
      color: "background",
      bg: "primary",
      position: "absolute",
      left: 0,
      top: 0,
      paddingTop: "10vh",
    }}
  />
)

export default InvertCS
