/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

const colors = {
  text: "black",
  background: "white",
  primary: "#b01010",
}
const util = {
  coolRedHighlight: {
    backgroundImage:
      "linear-gradient(transparent 55%,#ff6961 55%,#ff6961 95%,transparent 95%)",
    backgroundSize: "100% 1.2em",
    backgroundPosition: "0.5em 0px",
    backgroundRepeat: "no-repeat space",
    display: "inline-block",
    alignSelf: "center",
  },
}

const theme = {
  colors,
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  styles: {
    strong: util.coolRedHighlight,
    img: {
      width: null,
      height: null,
    },
    li: {
      textAlign: "left",
    },
    td: {
      padding: "10px;",
    },
    Slide: {
      maxWidth: "100%",
      margin: "auto",
      paddingRight: "5vh",
      paddingLeft: "5vh",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      textAlign: "left",
    },
    h1: {
      textAlign: "center",
    },
    h2: {
      textAlign: "center"
    },
    img: {
      display: "block",
      margin: "auto"
    }
  },
}

const styles = {
  coolRedHighlight: util.coolRedHighlight,
  content: {
    width: "90%",
    margin: "auto",
  },
  inverted: {
    backgroundColor: colors.primary,
    width: "100%",
    height: "100%",
    color: "white",
    position: "absolute",
    left: 0,
    top: 0,
  },
  slideCenteredContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
}

// If there's no title, just center all the content.

export const CSSlide = ({ title, children, inverted }) => {
  let slideStyle = styles.slideCenteredContent
  if (inverted) {
    slideStyle = { ...slideStyle, ...styles.inverted }
  }
  return (
    <div style={slideStyle}>
      {title && <h2 style={styles.coolRedHighlight}>{title}</h2>}
      <div style={styles.content}>{children}</div>
    </div>
  )
}

export const CSSplit = ({ children, ...props }) => {
  const [first, ...rest] = React.Children.toArray(children)
  return (
    <div
      {...props}
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "space-between",
      }}
    >
      <div sx={{ width: "50%" }}>{first}</div>
      <div sx={{ width: "50%" }}>{rest}</div>
    </div>
  )
}

export const CenterFull = ({ children }) => {
  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        height: '100%',
        width: '100%',
        justifyContent: 'center'
      }}
    >
        {children}
      </div>
  )
}

export default theme
