import { CodeSurfer } from "code-surfer";
import odm from "../../../../images/ftw/wk8/odm.png";
import mongoquery from "../../../../images/ftw/wk8/mongoquery.png";
import mongoosequery from "../../../../images/ftw/wk8/mongoosequery.png";
import core_concept from "../../../../images/ftw/wk8/core_concept.png";
import dataformat from "../../../../images/ftw/wk8/dataformat.png";
import badrestapi from "../../../../images/ftw/wk8/badrestapi.png";
import goodrestapi from "../../../../images/ftw/wk8/goodrestapi.png";
import * as React from 'react';
export default {
  CodeSurfer,
  odm,
  mongoquery,
  mongoosequery,
  core_concept,
  dataformat,
  badrestapi,
  goodrestapi,
  React
};