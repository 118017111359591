import { CodeSurfer } from "code-surfer";
import authentication from "../../../../images/ftw/wk8/authentication.png";
import authen_flow_one from "../../../../images/ftw/wk8/authen-flow-1.png";
import authen_flow_two from "../../../../images/ftw/wk8/authen-flow-2.png";
import * as React from 'react';
export default {
  CodeSurfer,
  authentication,
  authen_flow_one,
  authen_flow_two,
  React
};