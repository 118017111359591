import { CodeSurfer } from "code-surfer";
import websocket from "../../../../images/ftw/wk9/websocket.png";
import chat_second from "../../../../images/ftw/wk9/chat_second.png";
import chat_third from "../../../../images/ftw/wk9/chat_third.png";
import * as React from 'react';
export default {
  CodeSurfer,
  websocket,
  chat_second,
  chat_third,
  React
};